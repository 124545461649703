@font-face {
    font-family: "Hind Madurai";
    src: url("../data/fonts/Hind_Madurai/HindMadurai-Medium.ttf")
      format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Playfair Display";
    src: url("../data/fonts/Playfair_Display/static/PlayfairDisplay-Medium.ttf")
      format("truetype");
    font-weight: normal;
    font-style: normal;
  }


.footer-info {
    color: white;
}

.footerheader {
    font-family: "Playfair Display", serif;
}

.infotext {
    font-family: "Hind Madurai", sans-serif;
}