@font-face {
  font-family: "Hind Madurai";
  src: url("../data/fonts/Hind_Madurai/HindMadurai-Medium.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Playfair Display";
  src: url("../data/fonts/Playfair_Display/static/PlayfairDisplay-Medium.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.head6 {
  text-align: center;
  font-family: "Playfair Display", serif;
  color: #240701!important;
  margin-bottom: 15px;
}
