@font-face {
  font-family: "Hind Madurai";
  src: url("../data/fonts/Hind_Madurai/HindMadurai-Medium.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Playfair Display";
  src: url("../data/fonts/Playfair_Display/static/PlayfairDisplay-Medium.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.head6 {
  text-align: center;
  font-family: "Playfair Display", serif;
  color: #240701 !important;
}

.btn-no-radius {
  border-radius: 0 !important;
  background-color: #240701 !important;
  border-color: #0b0200 !important;
}

.btn-no-radius:hover {
  background-color: black !important;
}

.no-border-radius-input {
  border-radius: 0 !important;
  background-color: #f8f9fa !important;
  border: 1px solid #ced4da !important;
  font-family: "Hind Madurai", sans-serif;
  color: #6c757d !important;
}

.no-border-radius-input:focus {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
  background-color: white !important;
}

.textfont {
  font-family: "Hind Madurai Medium", sans-serif !important;
}

@media (max-width: 767px) {
  .contact-container {
    margin-top: 40px;
  }
}