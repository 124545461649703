@font-face {
    font-family: "Playfair Display";
    src: url("../data/fonts/Playfair_Display/static/PlayfairDisplay-Medium.ttf")
      format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Hind Madurai";
    src: url("../data/fonts/Hind_Madurai/HindMadurai-Medium.ttf")
      format("truetype");
    font-weight: normal;
    font-style: normal;
  }

.overlayThree {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* black with 50% opacity */
    z-index: 0; /* set z-index to 0 so that it appears behind the content */
    max-width: 1509px;
  }

  .image-container {
    position: relative;
    margin: 50px;
  }

  .text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }
  
  .text-white {
    font-size: 3vw;
    font-family: "Playfair Display", serif;
    color: #fff;
  }


  .bookStay {
    margin-top: 20px;
    letter-spacing: .05em;
    font-family: "Hind Madurai", sans-serif;
    color: white;
    padding: 15px 25px;
    transition: all 0.3s ease-in-out;
    border: 1px solid #fff;
    display: inline-block;
   
  }

  .bookStay:hover {
    background-color: #fff;
    color: #000;
  }

  @media (max-width: 767px) {
    .bookStay {
      font-size: 0.7em;
    }
  }


  @media screen and (min-width: 1600px) {
    .text-white {
      font-size: 3rem;
    }
    .welcomehead {
      font-size: 4rem;
    }

  }
