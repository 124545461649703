@font-face {
  font-family: "Hind Madurai";
  src: url("../data/fonts/Hind_Madurai/HindMadurai-Medium.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Playfair Display";
  src: url("../data/fonts/Playfair_Display/static/PlayfairDisplay-Medium.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@media screen and (max-width: 768px) {
  #hide {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  #show {
    display: none;
  }
}

.container {
  width: 100%;
  height: 100%;
}

.HomePage {
  position: relative;
  height: 87vh;
  margin: 30px;
  max-width: 1600px;
}

.HomePage img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1; /* set z-index to -1 so that it appears behind the content */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* black with 50% opacity */
  z-index: 0; /* set z-index to 0 so that it appears behind the content */
}

.headercontent {
  display: flex;
  align-items: flex-start;
  color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1; /* set z-index to 1 so that it appears on top of the image and overlay */
  top: 40px;
  left: 30px;
  z-index: 1;
}

.headercontent h1,
.headercontent p {
  color: white;
}

.fontfamily {
  max-width: 1550px;
  white-space: nowrap;
  font-family: "Playfair Display", serif;
  font-size: 2vw;
  font-weight: bold;
  font-stretch: 100%;
}
.fontfamily2 {
  white-space: nowrap;
  font-family: "Playfair Display", serif;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: 100%;
}

.nav-links {
  margin-top: 8px;
  margin-left: 10px;
  list-style-type: none;
  display: flex;
}

.nav-links li {
  white-space: nowrap;
  font-family: "Hind Madurai", sans-serif;
  cursor: pointer;
  margin-right: 20px;
  color: white;
}

.book-now {
  text-align: center;
  letter-spacing: 0.05em;
  font-size: 0.83333rem;
  font-weight: 700;
  font-family: "Hind Madurai", sans-serif;
  margin-right: 60px;
  width: 150px;
  padding: 10px;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.book-now:hover {
  letter-spacing: 0.05em;
  font-size: 0.83333rem;
  font-weight: 700;
  font-family: "Hind Madurai", sans-serif;
  background-color: white;
  color: black;
}

.welcome {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: bold;
  color: white;
}

.nord {
  max-width: 1600px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.5vw;
  white-space: nowrap;
  font-family: "Hind Madurai", sans-serif;
}

.welcomehead {
  max-width: 1600px;
  font-size: 4vw;
  font-family: "Playfair Display", serif;
  white-space: nowrap;
}

.dropContainer {
  width: 300px;
}

.dropContainer a:hover,
.dropContainer a:focus,
.dropContainer button:focus {
  background-color: transparent;
  color: #000000; /* change to desired text color */
  outline: none;
  text-decoration: none;
}

.dropdown-toggle::after {
  display: none !important;
}

@media screen and (min-width: 1600px) {
  #text1 {
    font-size: 1.8rem;
  }
  .welcomehead {
    font-size: 4rem;
  }
  .nord {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1366px) {
  .welcomehead {
    font-size: 3rem;
  }
  .nord {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1112px) {
  .welcomehead {
    font-size: 2.5rem;
  }
  .nord {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1024px) {
  .welcomehead {
    font-size: 2.5rem;
  }
  .nord {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 414px) {
  .welcomehead {
    font-size: 1.5rem;
  }
  .nord {
    font-size: 1rem;
  }
}

@media screen and (max-width: 375px) {
  .welcomehead {
    font-size: 1.2rem;
  }
  .nord {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 320px) {
  .welcomehead {
    font-size: 1.2rem;
  }
  .nord {
    font-size: 0.7rem;
  }
}

.siggi {
  position: absolute;
  top: 93%;
  left: 50%;
  color: white;
  z-index: 1;
}

.siggi:hover {
  cursor: pointer;
}
