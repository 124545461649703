@font-face {
  font-family: "Hind Madurai";
  src: url("../data/fonts/Hind_Madurai/HindMadurai-Medium.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Playfair Display";
  src: url("../data/fonts/Playfair_Display/static/PlayfairDisplay-Medium.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.guesthouse {
  position: relative;
  margin: 30px;
  max-width: 1600px;
  width: 100%;
  text-align: center;
}

.guesthouse img {
  width: 100%;
  display: inline-block;
  z-index: -1;
  max-width: 1600px;
}

.overlay5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* black with 50% opacity */
  z-index: 0;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.7vw;
  font-weight: bold;
  color: white;
  font-family: "Playfair Display", sans-serif;
  max-width: 1600px;
}

@media screen and (min-width: 1600px) {
  .text {
    font-size: 2.7rem;
  }
}
