.homeButton {
    text-align: center;
    letter-spacing: 0.05em;
    font-size: 0.83333rem;
    font-weight: 700;
    font-family: "Hind Madurai", sans-serif;
    margin-right: 60px;
    width: 100px;
    padding: 10px;
  
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin: 5px;
    text-decoration: none;
    
}

