@font-face {
  font-family: "Playfair Display";
  src: url("../data/fonts/Playfair_Display/static/PlayfairDisplay-Medium.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Hind Madurai";
  src: url("../data/fonts/Hind_Madurai/HindMadurai-Medium.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.containerImageCards {
  margin-top: 50px;
}

.image {
  outline: 0 !important;
  border-style: none !important;
  height: fit-content;
  width: 100%;
}
.overlayTwo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* adjust the alpha value for the transparency */
  z-index: 1;
}

.cardTitle {
  z-index: 1;
  font-family: "Playfair Display", serif;
  font-size: 1.728rem !important;
}

.cardim {
  height: 400px;
}


