.h4head {
  margin-top: -40px;
  margin-bottom: 30px;
  font-family: "Playfair Display", serif;
  text-align: center;
}

.paragr {
  color: #7f7f7f;
 
}

.spanparagr {
  margin-bottom: 80px;
}




.paragr {
    margin: auto;
    font-family: "Hind Madurai", sans-serif;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
  }
